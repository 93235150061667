import React, { useState } from "react"

import Button from "~/components/button"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const [state, setState] = useState({})
  const [submitted, setSubmitted] = useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setSubmitted(true)
        setTimeout(() => {
          setSubmitted(false)
          setState({})
        }, 5000)
      })
      .catch(error => alert(error))
  }

  const renderForm = () => (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className="w-full max-w-lg mb-auto"
    >
      <input type="hidden" name="form-name" value="contact" />
      <label className="hidden">
        Don’t fill this out if you’re human:
        <input onChange={handleChange} name="bot-field" />
      </label>

      <div className="overflow-hidden space-y-6 px-1 pb-2">
        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3 sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-base font-medium text-gray-700"
            >
              Your Name
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                onChange={handleChange}
                type="text"
                name="name"
                id="name"
                required
                className="focus:ring-orange focus:border-orange flex-1 block w-full rounded sm:text-sm border-gray-300"
                placeholder="Joe Bloggs"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-3 sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-base font-medium text-gray-700"
            >
              Your Email
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                onChange={handleChange}
                type="email"
                name="email"
                id="email"
                required
                className="focus:ring-orange focus:border-orange flex-1 block w-full rounded sm:text-base border-gray-300"
                placeholder="name@example.com"
              />
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="message"
            className="block text-base font-medium text-gray-700"
          >
            Your Message
          </label>
          <div className="mt-1">
            <textarea
              onChange={handleChange}
              id="message"
              name="message"
              required
              rows="5"
              className="shadow-sm focus:ring-orange focus:border-orange mt-1 block w-full sm:text-base border-gray-300 rounded-md"
              placeholder="Write something nice here :)"
            ></textarea>
          </div>
        </div>

        <div className="prose">
          <Button type="submit">Send</Button>
        </div>
      </div>
    </form>
  )

  const renderThanks = () => (
    <div className="space-y-2 flex flex-col items-center justify-center h-full text-center text-lg bg-pink">
      <p>Thanks for your message, {state.name}!</p>
      <p>
        I will try to get back to you as soon as I can.{" "}
        <span role="img" aria-label="SMILING FACE WITH SMILING EYES">
          &#128522;
        </span>
      </p>
    </div>
  )

  return (
    <div style={{ height: "420px" }}>
      {submitted && renderThanks()}
      {!submitted && renderForm()}
    </div>
  )
}
