import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import ContactForm from "~/components/contact-form"
import SectionTitle from "~/components/section-title"
import Container from "~/components/container"
import useContactPage from "~/hooks/use-contact-page"

const Contact = () => {
  const { coverImage, sketch } = useContactPage()

  return (
    <>
      <Seo
        title="Contact"
        description="Whether you are looking for a simple bouquet to lift someones
                spirits or a quote for a wedding, I would love to hear from
                you. Please feel free to send me a message or phone me."
      />

      <PageHero title="Contact" image={coverImage} />

      <section>
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 gap-8">
            <div className="row-start-2 lg:row-start-auto xl:col-start-2 xl:col-span-5">
              <SectionTitle
                title="Get in touch!"
                classes={["md:inline-flex py-4 px-8 mb-8 lg:mb-10"]}
              />
              <div className="px-1 space-y-2 mb-10 text-base">
                <p className="mb-3">
                  Whether you are looking for a simple bouquet to lift someone's
                  spirits or a quote for a wedding, I would love to hear from
                  you. Please feel free to send me a message or phone me.
                </p>

                <p>
                  <b>e:</b>{" "}
                  <a
                    className="text-orange hover:text-orange-dark"
                    title="HeartBee Flowers Contact Email"
                    href="mailto:hello@heartbeeflowers.com?subject=New enquiry"
                  >
                    hello@heartbeeflowers.com
                  </a>
                </p>

                <p>
                  <b>t:</b>{" "}
                  <a
                    className="text-orange hover:text-orange-dark"
                    href="tel:01962621097"
                    title="HeartBee Flowers Phone number"
                  >
                    01962 621 097
                  </a>
                </p>
              </div>
            </div>

            <div className="row-start-1 lg:row-start-auto lg:mt-0 flex-shrink-0 w-full xl:col-span-5">
              <ContactForm />
            </div>
          </div>

          <div className="flex items-center">
            <GatsbyImage
              image={sketch}
              className="mt-10 mx-auto w-48"
              alt="HeartBee Flowers · Winchester"
            />
          </div>
        </Container>
      </section>
    </>
  )
}

export default Contact
